<template>
  <div>
    <LoadingState v-if="isLoading" />
    <main-card v-else>
      <span slot="title1">Homeroom Reports</span>
      <span slot="title2">{{ header.kelas }}</span>
      <span slot="sub-title1">{{ header.totalMurid }} Students</span>
      <span slot="sub-title2"
        >{{ header.tahunAjar }} ({{ header.semester }})</span
      >
      <div slot="content">
        <a-menu v-model="currentMenu" mode="horizontal">
          <a-menu-item key="dailyAttendanceReport"
            >Daily Attendance Report</a-menu-item
          >
          <a-menu-item key="periodicReport">Periodic Report</a-menu-item>
          <a-menu-item key="generalReport">General Report</a-menu-item>
          <a-menu-item key="ledgerReport">Ledger Report</a-menu-item>
          <a-menu-item key="graduationLetter">Graduation Letter</a-menu-item>
          <!-- <a-menu-item key="sessionPresences">Session Presences</a-menu-item> -->
        </a-menu>
        <div class="mt-4">
          <table-daily-attendance-report
            v-if="currentMenu[0] === 'dailyAttendanceReport' && header.idKelas"
            :dataClass="header"
          />
          <table-periodic-report
            v-if="currentMenu[0] === 'periodicReport' && header.idKelas"
            :dataClass="header"
          />
          <table-general-report
            v-if="currentMenu[0] === 'generalReport' && header.idKelas"
            :dataClass="header"
          />
          <table-ledger
            v-if="currentMenu[0] === 'ledgerReport' && header.idKelas"
            :subjects="subjects"
          />
          <table-graduation-letter
            v-if="
              currentMenu[0] === 'graduationLetter' &&
              header.idKelas &&
              header.isLastLevel
            "
            :dataClass="header"
          />
        </div>
      </div>
    </main-card>
  </div>
</template>

<script>
const mainCard = () => import('@/components/app/shared/mainCard')
const LoadingState = () => import('@/components/app/LoadingState')
const TableDailyAttendanceReport = () =>
  import('@/components/app/Homeroom/TableDailyAttendanceReport')
const TablePeriodicReport = () =>
  import('@/components/app/Homeroom/TablePeriodicReport')
const TableGeneralReport = () =>
  import('@/components/app/Homeroom/TableGeneralReport')
const TableLedger = () => import('@/components/app/Homeroom/TableLedger')
const TableGraduationLetter = () =>
  import('@/components/app/Homeroom/TableGraduationLetter')
export default {
  components: {
    mainCard,
    LoadingState,
    TableDailyAttendanceReport,
    TablePeriodicReport,
    TableGeneralReport,
    TableLedger,
    TableGraduationLetter,
  },
  data() {
    return {
      header: {},
      currentMenu: ['dailyAttendanceReport'],
      subjects: [],
      idWaliKelas: null,
      isCurriculum: false,
    }
  },
  methods: {
    fetchHeader() {
      this.$store
        .dispatch('homeroom/FETCH_HEADER_PERSONAL_DATA', {
          idGuru: this.idWaliKelas,
          isCurriculum: this.isCurriculum,
        })
        .then((res) => {
          this.header = res
          this.fetchDataSubject()
        })
    },
    fetchDataSubject() {
      this.$store
        .dispatch('homeroom/FETCH_HOMEROOM_SELECT', {
          idGuru: this.idWaliKelas,
          isCurriculum: this.isCurriculum,
        })
        .then((res) => {
          res.selectMapel.unshift({
            id_mata_pelajaran: 'All',
            mata_pelajaran: {
              nama: 'All Subjects',
            },
          })
          this.subjects = res.selectMapel
        })
    },
  },
  mounted() {
    if (this.$route.query.isCurriculum) {
      this.isCurriculum = true
      this.idWaliKelas = this.$route.query.id_walikelas
    } else {
      this.idWaliKelas = this.user.id
    }
    this.fetchHeader()
    this.fetchDataSubject()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    isLoading() {
      return this.$store.state.isLoading
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style lang="scss">
.scrolling-card {
  height: 600px;
  overflow-y: auto;
}
</style>
